import axios, {AxiosResponse} from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import Product from "../models/Product";
import {Page} from "../commons/functionals/general_models/Page";

export default class StoreService {
    async getProducts(): Promise<Page<Product>> {
        const response: AxiosResponse<Page<Product>> = await axios.get<Page<Product>>(apiRoutes.getProducts, {withCredentials: true});
        return response.data;
    }
}