import React, {useContext, useEffect, useState} from "react";
import CreateJobRequest from "../../../models/requests/CreateJobRequest";
import {convertToName, ExperienceLevels} from "../../../models/enums/ExperienceLevels";
import JobsService from "../../../services/JobsService";
import {MdModeEdit, MdPostAdd} from "react-icons/md";
import Loading from "../../../commons/component/loading/views/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {AuthContext} from "../../../commons/functionals/auth_logic/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import JobsRouter from "../../../routers/JobsRouter";
import {toast, Toaster} from "react-hot-toast";
import {convertToDisplayText, JobType} from "../../../models/enums/JobType";
import JobTitleCompleteResponse from "../../../models/responses/JobTitleCompleteResponse";


export default function JobsCreatePage() {
    const {user} = useContext(AuthContext);
    const [isLoadingJobTitle, setIsLoadingJobTitle] = useState<boolean>(false);
    const [searchedJobsResult, setSearchedJobsResult] = useState<JobTitleCompleteResponse[]>([]);
    const [searchJobTerm, setSearchJobTerm] = useState<string>("");
    const [selectedJobTitle, setSelectedJobTitle] = useState<JobTitleCompleteResponse | null>(null);
    const [isCreatingJob, setIsCreatingJob] = useState<boolean>(false);
    const [isPopupShowing, setIsPopupShowing] = useState<boolean>(false);
    const [createdJobSlug, setCreatedJobSlug] = useState<string | null>(null);
    const [buyButtonAvailable, setBuyButtonAvailable] = useState<boolean | null>(null);
    const navigate = useNavigate();

    const [request, setRequest] = useState<CreateJobRequest>({
        jobTitleSlug: "",
        employeesNumberRequired: 0,
        jobType: JobType.intern,
        experienceLevel: ExperienceLevels.None,
        monthlySalary: 4000,
        startTimeTimestamp: 0,
        daysPerWeek: 1,
        hoursPerDay: 4,
    });
    const jobsService = new JobsService();
    const debounceDelay = 500;

    useEffect(() => {
        if (searchJobTerm.trim() === "") return;

        const timerId = setTimeout(() => {
            searchJobs();
        }, debounceDelay);

        return () => clearTimeout(timerId);
    }, [searchJobTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchJobTerm(event.target.value);
    };

    const handleItemClick = (item: JobTitleCompleteResponse) => {
        setSelectedJobTitle(item);
        setSearchJobTerm("");
        setRequest((prev) => ({...prev, jobTitleSlug: item.data.slug}));
        setBuyButtonAvailable(item.hasCredits);
    };

    const searchJobs = () => {
        setIsLoadingJobTitle(true);
        jobsService.getJobName(searchJobTerm)
            .then((response) => setSearchedJobsResult(response))
            .catch((_) => {
            })
            .finally(() => setIsLoadingJobTitle(false));
    }


    const updatePersons = (valueToAdd: number) => {
        if (request.employeesNumberRequired + valueToAdd < 0) return;
        setRequest((prev) => ({...prev, employeesNumberRequired: request.employeesNumberRequired + valueToAdd}));
    }

    const handleDateStartChange = (value: Date | null) => {
        if (value == null) return;
        setRequest((prev) => ({...prev, startTimeTimestamp: value.getTime()}));
    };

    const handleHoursClick = (value: number) => {
        setRequest((prev) => ({...prev, hoursPerDay: value}));
    };

    const postJob = () => {
        if (request.jobTitleSlug.length === 0) {
            toast.error("Introu numele jobului");
            return;
        }

        if (request.employeesNumberRequired === 0) {
            toast.error("Trebuie sa angajezi cel putin o persoana");
            return;
        }

        if (request.monthlySalary === 0) {
            toast.error("Introdu un salar");
            return;
        }

        if (request.startTimeTimestamp <= Date.now()) {
            toast.error("Data introdusă trebuie să fie în viitor");
            return;
        }

        if (request.hoursPerDay <= 0) {
            toast.error("Introdu numarul de ore pe zi in medie");
            return;
        }

        if (request.daysPerWeek === 0) {
            toast.error("Persoana angajata trebuie sa lucreze minim o zi");
            return;
        }

        setIsCreatingJob(true);
        jobsService.create(user?.firmSlug ?? "", request)
            .then((rsp) => {
                setSelectedJobTitle(null);
                setBuyButtonAvailable(null);
                setCreatedJobSlug(rsp);
                setIsPopupShowing(true);
            })
            .catch((_) => {
                toast.error("Eroare la crearea jobului. Incearca din nou!");
            })
            .finally(() => setIsCreatingJob(false))
    }

    const selectExperienceLevel = (level: ExperienceLevels) => {
        setRequest((prev) => ({...prev, experienceLevel: level}));
    }

    const selectJobType = (jobType: JobType) => {
        setRequest((prev) => ({...prev, jobType: jobType}));
    }

    const handleSalaryChange = (value: string) => {
        setRequest((prev) => ({...prev, monthlySalary: parseFloat(value)}));
    }

    const handleDaysPerWeek = (value: string) => {
        setRequest((prev) => ({...prev, daysPerWeek: parseInt(value)}));
    }

    const navigateToShop = () => {
        navigate(ApplicationRoutes.dashboard + DashboardUserRouter.shop);
    }

    return (
        <>
            <div><Toaster/></div>
            <div className={"w-full h-fit flex flex-col md:flex-row-reverse gap-3"}>
                <div className={"md:top-0 md:sticky w-full h-fit md:w-1/3 rounded-xl bg-white p-3"}>
                    {
                        selectedJobTitle != null
                            ? <p>Cost credite total: {selectedJobTitle.data.jobPostCreditCost} credite</p>
                            : <p className={"text-sm text-gray-400"}>
                                Selecteaza numele jobului pentru a afisa costul creditelor
                            </p>
                    }
                    {
                        buyButtonAvailable === false
                            ? <p className={"text-sm text-error"}>Nu ai destule credite pentru a crea acest job.</p>
                            : <></>
                    }
                    <button
                        onClick={!buyButtonAvailable ? navigateToShop : postJob}
                        disabled={selectedJobTitle == null}
                        className={"flex justify-center items-center w-full p-2 bg-accent-main-500 my-2 rounded-lg text-white disabled:bg-gray-200 disabled:text-gray-400"}>
                        {
                            isCreatingJob
                                ? <Loading/>
                                : buyButtonAvailable ? "Creaza job" : "Catre magazin"
                        }
                    </button>
                </div>
                <div className={"w-full"}>
                    <div className={"w-full rounded-xl bg-white p-3"}>
                        <div
                            className={`transition-all duration-500 overflow-hidden ${isCreatingJob ? "max-h-0" : "max-h-40"}`}>
                            <h1 className={`flex flex-row justify-start items-center gap-2 text-3xl border-b mb-3 py-3 text-accent-main-500 border-accent-main-500 transition-opacity duration-500 transform ${isCreatingJob ? "opacity-0" : "opacity-100"}`}>
                                <MdPostAdd/>
                                Creaza un anunt
                            </h1>
                        </div>
                        {
                            selectedJobTitle == null ?
                                <>
                                    <label className="mt-3 text-sm">Cauta numele jobului pe care doresti sa il
                                        postezi</label>
                                    <div className="mt-2 mb-4">
                                        <input
                                            type="text"
                                            value={searchJobTerm}
                                            onChange={handleSearchChange}
                                            placeholder="Cauta joburi..."
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"
                                        />
                                    </div>
                                    {searchJobTerm.trim().length !== 0 && (
                                        <div className="mb-4">
                                            {isLoadingJobTitle
                                                ? (<Loading color="fill-accent-main-500"/>) :
                                                (
                                                    searchedJobsResult.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => handleItemClick(item)}
                                                            className="cursor-pointer bg-gray-100 py-2.5 px-5 rounded-lg mb-1 flex gap-2"
                                                        >
                                                            {item.data.name}
                                                        </div>
                                                    ))
                                                )}
                                        </div>
                                    )}
                                </>
                                :
                                <div
                                    className={`duration-300 flex flex-col gap-5 overflow-hidden ${isCreatingJob ? "max-h-[120px]" : "max-h-[1000px]"}`}
                                    style={{transition: "max-height 0.5s ease"}}>
                                    <div className={"flex justify-between bg-gray-100 rounded-lg p-5"}>
                                        <div>
                                            <p className={"text-2xl text-accent-main-500 font-bold"}>
                                                {selectedJobTitle.data.name}
                                            </p>
                                            <div className={"text-gray-400"}>
                                                Cod COR asociat: <b
                                                className={"text-black"}>{selectedJobTitle.data.corCode}</b>
                                            </div>
                                            <div className={"text-gray-400"}>
                                                {selectedJobTitle.data.description}
                                            </div>
                                        </div>
                                        {
                                            isCreatingJob
                                                ? <></>
                                                : <button onClick={() => {
                                                    setSelectedJobTitle(null);
                                                    setBuyButtonAvailable(null);
                                                }}
                                                          className={"h-fit p-2 rounded-xl bg-accent-main-500 shadow-lg text-white flex gap-1 justify-center items-center"}>
                                                    <MdModeEdit/>
                                                    Editeaza
                                                </button>
                                        }
                                    </div>

                                    <div className={"flex flex-col gap-5 bg-gray-100 rounded-lg p-5"}>
                                        <p className={"text-2xl font-bold"}>
                                            Doresc sa angajez:
                                        </p>
                                        <div className={"flex gap-4 justify-center items-center"}>
                                            <div className="flex space-x-2 justify-center p-4">
                                                {Object.values(JobType).filter(j => j !== JobType.none).map((jobType) => (
                                                    <button
                                                        key={jobType}
                                                        onClick={() => selectJobType(jobType)}
                                                        className={`px-4 py-2 rounded-lg font-semibold transition-colors 
                                                    ${request.jobType === jobType ? 'bg-accent-main-500 text-white' : 'bg-gray-300 text-gray-800'}
                                                    hover:bg-accent-main-400 hover:text-white`}
                                                    >
                                                        {convertToDisplayText(jobType)}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"flex flex-col gap-5 bg-gray-100 rounded-lg p-5"}>
                                        <p className={"text-2xl font-bold"}>
                                            Numarul de persoane pe acest post:
                                        </p>
                                        <div className={"flex gap-4 justify-center items-center"}>
                                            <button onClick={() => updatePersons(-1)}
                                                    className={"px-4 py-2 bg-accent-main-500 text-white rounded-xl"}>
                                                -
                                            </button>
                                            <div
                                                className={"bg-white w-[100px] flex items-center justify-center border rounded-xl px-5 py-3"}>{request.employeesNumberRequired}</div>
                                            <button onClick={() => updatePersons(+1)}
                                                    className={"px-4 py-2 bg-accent-main-500 text-white rounded-xl"}>
                                                +
                                            </button>
                                        </div>
                                    </div>

                                    <div className={"flex flex-col bg-gray-100 rounded-lg gap-5 p-5"}>
                                        <p className={"text-2xl font-bold"}>
                                            Experienta necesara:
                                        </p>
                                        <div className="flex space-x-2 justify-center p-4">
                                            {Object.values(ExperienceLevels).map((level) => (
                                                <button
                                                    key={level}
                                                    onClick={() => selectExperienceLevel(level)}
                                                    className={`px-4 py-2 rounded-lg font-semibold transition-colors 
                                                    ${request.experienceLevel === level ? 'bg-accent-main-500 text-white' : 'bg-gray-300 text-gray-800'}
                                                    hover:bg-accent-main-400 hover:text-white`}
                                                >
                                                    {convertToName(level)}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    <div className={"flex flex-col bg-gray-100 rounded-lg gap-5 p-5"}>
                                        <p className={"text-2xl font-bold"}>
                                            Alte detalii:
                                        </p>
                                        <div className={"flex justify-center items-center gap-5"}>
                                            <p>Data de start: </p>
                                            <div className="flex justify-center">
                                                <DatePicker
                                                    className={"p-2 rounded-lg border text-center outline-none"}
                                                    selected={request.startTimeTimestamp === 0 ? new Date() : new Date(request.startTimeTimestamp)}
                                                    onChange={(date) => handleDateStartChange(date)}
                                                    dateFormat="dd MMM yyyy"
                                                />
                                            </div>
                                        </div>

                                        <div className={"flex justify-center items-center gap-5"}>
                                            <p>Salariul pe luna: </p>
                                            <div className="flex justify-center">
                                                <input value={request.monthlySalary}
                                                       onChange={(e) => handleSalaryChange(e.target.value)}
                                                       type={"number"}
                                                       min={3700}
                                                       className={"p-3 rounded-lg border bg-white outline-none"}/>
                                            </div>
                                        </div>

                                        <div className={"flex justify-center items-center gap-5"}>
                                            <p>Zile de lucru pe saptamana: </p>
                                            <div className="flex justify-center gap-2">
                                                <input value={request.daysPerWeek}
                                                       onChange={(e) => handleDaysPerWeek(e.target.value)}
                                                       type={"number"}
                                                       min={1}
                                                       max={7}
                                                       className={"px-5 py-2.5 rounded-lg border bg-white outline-none"}/>
                                            </div>
                                        </div>

                                        <div className={"flex justify-center items-center gap-5"}>
                                            <p>Ore de lucru pe zi: </p>
                                            <div className="flex justify-center gap-2">
                                                {
                                                    [4, 6, 8, 10, 12].map((el) =>
                                                        <div
                                                            onClick={() => handleHoursClick(el)}
                                                            className={`cursor-pointer rounded-lg ${request.hoursPerDay === el ? "bg-accent-main-500 text-white" : "bg-gray-300"} py-1.5 px-3`}>
                                                            {el}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    {
                        isCreatingJob
                            ? <p className={"p-5 flex justify-center gap-2 items-center text-lg text-gray-400"}>
                                <Loading color={"fill-gray-400"}/>
                                Se creaza jobul
                            </p>
                            : <></>
                    }
                </div>
            </div>
            {
                isPopupShowing
                    ?
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg w-1/3 h-fit p-6 relative">
                            <div className="flex mb-4">
                                <h2 className="text-center w-full text-3xl font-semibold">Job postat cu
                                    success</h2>
                            </div>

                            <div className="text-gray-400 flex flex-col justify-center items-center">
                                <p className={"mb-10"}>Felicitări! Jobul tău a fost postat cu succes! 🎉</p>
                                <div className={"flex flex-col"}>
                                    <Link
                                        to={ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.viewJobEntry + createdJobSlug}
                                        className={"mb-2 px-4 py-2 rounded-xl bg-accent-main-500 text-white"}
                                    >
                                        Vezi jobul postat
                                    </Link>
                                    <Link
                                        to={ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.browse}>
                                        Vezi lista ta de joburi
                                    </Link>
                                    <Link to={ApplicationRoutes.dashboard + DashboardUserRouter.shop}>
                                        Achizitioneaza credite
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    );
}