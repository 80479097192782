import axios, {AxiosResponse} from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import CreateJobRequest from "../models/requests/CreateJobRequest";
import JobPosting from "../models/JobPosting";
import {Page} from "../commons/functionals/general_models/Page";
import {JobType} from "../models/enums/JobType";
import JobTitleCompleteResponse from "../models/responses/JobTitleCompleteResponse";

export default class JobsService {

    async create(firmSlug: string, request: CreateJobRequest): Promise<string> {
        const response: AxiosResponse<string> = await axios.post<string>(apiRoutes.createJob.replaceAll("SLUG", firmSlug), request, {withCredentials: true});
        return response.data;
    }

    async update(firmSlug: string, request: CreateJobRequest): Promise<string> {
        const response: AxiosResponse<string> = await axios.put<string>(apiRoutes.updateJob.replaceAll("JOB_SLUG", request.jobTitleSlug).replaceAll("SLUG", firmSlug), request, {withCredentials: true});
        return response.data;
    }

    async getJobName(jobName: string): Promise<JobTitleCompleteResponse[]> {
        const response: AxiosResponse<JobTitleCompleteResponse[]> = await axios.get<JobTitleCompleteResponse[]>(apiRoutes.getJobName + jobName, {withCredentials: true});
        return response.data;
    }

    async getMyJobs(page: number, filterJobType: JobType): Promise<Page<JobPosting>> {
        const response: AxiosResponse<Page<JobPosting>> = await axios.get<Page<JobPosting>>(apiRoutes.getMyJobs + "?page=" + page + (filterJobType !== JobType.none ? "&jobType=" + filterJobType : ""), {withCredentials: true});
        return response.data;
    }

    async getJob(jobSlug: string): Promise<JobPosting> {
        const response: AxiosResponse<JobPosting> = await axios.get<JobPosting>(apiRoutes.getJobs + jobSlug, {withCredentials: true});
        return response.data;
    }

}