import UserResponse from "../commons/component/header/functionals/models/UserResponse";
import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/auth_logic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";

export default class AuthService {
    async getUserDetails(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axiosInstance.get<UserResponse>(apiRoutes.user);
        return response.data;
    }
}