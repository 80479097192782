import {useContext, useEffect, useState} from "react";
import Firm from "../../models/Firm";
import FirmService from "../../services/FirmService";
import {AuthContext} from "../../commons/functionals/auth_logic/AuthContext";
import {IoChevronDown, IoPin} from "react-icons/io5";
import {HiBuildingOffice2} from "react-icons/hi2";
import {FaMoneyBillTransfer, FaMonument} from "react-icons/fa6";
import {PiIdentificationBadgeLight} from "react-icons/pi";
import {MdFormatListNumbered} from "react-icons/md";
import Loading from "../../commons/component/loading/views/Loading";
import {FirmTransaction} from "../../models/FirmTransaction";
import {GoPlus} from "react-icons/go";
import {FiMinus} from "react-icons/fi";

export default function FirmDetails() {

    const {user} = useContext(AuthContext);
    const [firm, setFirm] = useState<Firm | null>(null);
    const [isLoadingFirm, setIsLoadingFirm] = useState<boolean>(false);

    const [firmTransactions, setFirmTransactions] = useState<FirmTransaction[]>([]);
    const [isLoadingFirmTransactions, setIsLoadingFirmTransactions] = useState<boolean>(false);
    const firmService = new FirmService();

    useEffect(() => {
        getFirmDetails();
        getFirmTransactions();
    }, []);

    const getFirmDetails = () => {
        setIsLoadingFirm(true);
        firmService.getDetails(user?.firmSlug ?? "")
            .then((response) => setFirm(response))
            .catch((_) => {
            })
            .finally(() => setIsLoadingFirm(false));
    }

    const getFirmTransactions = () => {
        setIsLoadingFirmTransactions(true);
        firmService.getFirmTransactions(user?.firmSlug ?? "")
            .then((response) => setFirmTransactions(response))
            .catch((_) => {
            })
            .finally(() => setIsLoadingFirmTransactions(false));
    }

    const loadingComponent = () =>
        <div className={"w-full flex items-center justify-center p-4"}>
            <Loading color={"fill-accent-main-500"}/>
        </div>;

    return (
        <div className={"p-2 flex flex-col gap-6"}>
            <section className={"text-center md:text-start rounded-lg shadow-lg bg-white"}>
                {
                    isLoadingFirm
                        ? loadingComponent()
                        :
                        <>
                            <div className={"px-5 py-2.5"}>
                                <h1 className={"text-2xl text-accent-main-500 flex gap-2 items-center tracking-wider"}>
                                    <HiBuildingOffice2/>
                                    {firm?.name ?? "No firm"}
                                </h1>
                                <p className={"text-sm text-gray-500 flex gap-1 items-center tracking-wider"}>
                                    <FaMonument/>
                                    {firm?.cui ?? "No CUI"}
                                </p>
                            </div>
                            <hr/>
                            <div className={"px-5 py-2.5 flex flex-col lg:flex-row gap-5"}>
                                <div className={""}>
                                    <p className={"text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1"}>
                                        <PiIdentificationBadgeLight/> Identificator pe platforma
                                    </p>
                                    <p className={"text-gray-500 text-sm"}>{firm?.slug ?? "No slug"}</p>
                                </div>
                                <div className={"border-r border-gray-300"}/>
                                <div className={""}>
                                    <p className={"text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1"}>
                                        <MdFormatListNumbered/> Numar de inregistrare
                                    </p>
                                    <p className={"text-gray-500 text-sm"}>{firm?.registrationNumber ?? "No Reg. No."}</p>
                                </div>
                                <div className={"border-r border-gray-300"}/>
                                <div className={""}>
                                    <p className={"text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1"}>
                                        Cod CAEN principal
                                        <IoChevronDown/>
                                    </p>
                                    <p className={"text-gray-500 text-sm"}>{firm?.mainCaenCode ?? "No CAEN"}</p>
                                </div>
                                <div className={"border-r border-gray-300"}/>
                                <div className={""}>
                                    <p className={"text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1"}>
                                        Coduri CAEN secundare
                                        <IoChevronDown/>
                                    </p>
                                    <p className={"text-gray-500 text-sm"}>
                                        {
                                            (firm?.secondaryCaenCode ?? "").split("|").join(", ")
                                        }
                                    </p>
                                </div>
                                <div className={"border-r border-gray-300"}/>
                                <div className={"w-full md:max-w-[300px]"}>
                                    <p className={"text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1"}>
                                        <IoPin/> Adresa Firmei
                                    </p>
                                    <p className={"text-gray-500 text-sm"}>
                                        {firm?.address ?? "No address"}
                                    </p>
                                </div>
                            </div>
                        </>
                }
            </section>
            <section className={"text-center md:text-start rounded-lg shadow-lg bg-white"}>
                {
                    isLoadingFirmTransactions
                        ? loadingComponent()
                        :
                        <>
                            <div className={"px-5 py-2.5 flex justify-between items-center"}>
                                <h1 className={"text-2xl text-accent-main-500 flex gap-2 items-center"}>
                                    <FaMoneyBillTransfer/>
                                    Istoric Tranzactii
                                </h1>
                                <div className={"text-accent-main-500"}>
                                    {firm?.availableCredits ?? "0"} credite curente
                                </div>
                            </div>
                            <hr/>
                            <div className="overflow-x-auto">
                                <table className="min-w-full">
                                    <thead className="border-b">
                                    <tr className={"text-left"}>
                                        <th className="px-6 text-center py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-[50px]">
                                            Tip
                                        </th>
                                        <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider">
                                            Identificator
                                        </th>
                                        <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider">
                                            Credite
                                        </th>
                                        <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider">
                                            Data
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {firmTransactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 flex justify-center items-center">
                                                {
                                                    transaction.transactionAmount > 0
                                                        ? <GoPlus
                                                            className={"text-green-500 h-[40px] w-[40px] bg-green-50 p-2 rounded-full"}/>
                                                        : <FiMinus
                                                            className={"text-red-500 h-[40px] w-[40px] bg-red-50 p-2 rounded-full"}/>
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="flex justify-start flex-col">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {transaction.slug}
                                                    </div>
                                                    <div className="text-sm text-gray-400">
                                                        {transaction.transactionAmount > 0 ? "credite adaugate" : "credite folosite"}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {transaction.transactionAmount} credite
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="ml-2 text-sm text-gray-400">
                                                    {new Date(transaction.createdAt).toLocaleDateString('en-GB', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric'
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                }
            </section>
        </div>
    );
}