import React, {useContext, useEffect, useState} from "react";
import CreateJobRequest from "../../../models/requests/CreateJobRequest";
import {convertToName, ExperienceLevels} from "../../../models/enums/ExperienceLevels";
import JobsService from "../../../services/JobsService";
import {MdPostAdd} from "react-icons/md";
import Loading from "../../../commons/component/loading/views/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {AuthContext} from "../../../commons/functionals/auth_logic/AuthContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import JobsRouter from "../../../routers/JobsRouter";
import {toast, Toaster} from "react-hot-toast";
import {convertToDisplayText, JobType} from "../../../models/enums/JobType";
import JobPosting from "../../../models/JobPosting";
import JobTitleCompleteResponse from "../../../models/responses/JobTitleCompleteResponse";


export default function JobsEditPage() {
    const {user} = useContext(AuthContext);
    const [isSavingJob, setIsSavingJob] = useState<boolean>(false);
    const [isLoadingJob, setIsLoadingJob] = useState<boolean>(false);
    const [isPopupShowing, setIsPopupShowing] = useState<boolean>(false);
    const [jobPosting, setJobPosting] = useState<JobPosting | null>(null);
    const [selectedJobTitle, setSelectedJobTitle] = useState<JobTitleCompleteResponse | null>(null);
    const jobsService = new JobsService();
    const {jobSlug} = useParams();
    const navigate = useNavigate();
    const [request, setRequest] = useState<CreateJobRequest>({
        jobTitleSlug: "",
        employeesNumberRequired: 0,
        jobType: JobType.intern,
        experienceLevel: ExperienceLevels.None,
        monthlySalary: 0.0,
        startTimeTimestamp: 0,
        hoursPerDay: 4,
        daysPerWeek: 1
    });

    useEffect(() => {
        setIsLoadingJob(true);
        jobsService.getJob(jobSlug ?? "")
            .then((response) => {
                console.log(response);
                setJobPosting(response)
                setRequest({
                    jobTitleSlug: response.slug,
                    employeesNumberRequired: response.requiredEmployees,
                    jobType: response.jobType,
                    experienceLevel: response.experienceLevel,
                    monthlySalary: response.monthlySalary,
                    startTimeTimestamp: response.startingTime,
                    hoursPerDay: response.hoursPerDay,
                    daysPerWeek: response.daysPerWeek
                });

                jobsService.getJobName(response.corCode.toString())
                    .then((response) => {
                        if (response.length !== 0)
                            setSelectedJobTitle(response[0]);
                    })
                    .catch((_) => setSelectedJobTitle(null));
            })
            .catch(() => {
            })
            .finally(() => setIsLoadingJob(false));
    }, []);

    useEffect(() => {
        console.log(request);
    }, [request]);

    const updatePersons = (valueToAdd: number) => {
        if ((request.employeesNumberRequired + valueToAdd) < 0 || (request.employeesNumberRequired + valueToAdd) > (jobPosting?.requiredEmployees ?? 0)) return;
        setRequest((prev) => ({...prev, employeesNumberRequired: request.employeesNumberRequired + valueToAdd}));
    }

    const handleDateStartChange = (value: Date | null) => {
        if (value == null) return;
        setRequest((prev) => ({...prev, startTimeTimestamp: value.getTime()}));
    };

    const saveJob = () => {
        if (request.employeesNumberRequired === 0) {
            toast.error("Trebuie sa angajezi cel putin o persoana");
            return;
        }

        if (request.monthlySalary === 0) {
            toast.error("Introdu un salar");
            return;
        }

        if (request.daysPerWeek === 0) {
            toast.error("Selecteaza zilele de lucru");
            return;
        }

        if (request.hoursPerDay <= 0) {
            toast.error("Introdu orele de lucru zilnice");
            return;
        }

        if (request.startTimeTimestamp <= (jobPosting?.startingTime ?? new Date().getTime())) {
            toast.error("Data introdusă trebuie să fie în viitor");
            return;
        }

        setIsSavingJob(true);
        jobsService.update(user?.firmSlug ?? "", request)
            .then((_) => {
                navigate(ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.viewJobEntry + jobPosting?.slug ?? "")
            })
            .catch((_) => {
                toast.error("Eroare la crearea jobului. Incearca din nou!");
            })
            .finally(() => setIsSavingJob(false))
    }

    const selectExperienceLevel = (level: ExperienceLevels) => {
        setRequest((prev) => ({...prev, experienceLevel: level}));
    }

    const handleSalaryChange = (value: string) => {
        setRequest((prev) => ({...prev, monthlySalary: parseFloat(value)}));
    }


    const handleDaysPerWeek = (value: string) => {
        setRequest((prev) => ({...prev, daysPerWeek: parseInt(value)}));
    }

    const handleHoursClick = (value: number) => {
        setRequest((prev) => ({...prev, hoursPerDay: value}));
    };

    const navigateToShop = () => {
        navigate(ApplicationRoutes.dashboard + DashboardUserRouter.shop);
    }

    return (
        <>
            <div><Toaster/></div>
            {
                isLoadingJob
                    ? <Loading/>
                    : <div className={"w-full h-fit flex flex-col lg:flex-row-reverse gap-3"}>
                        <div className={"lg:top-0 lg:sticky w-full h-fit lg:w-1/3 rounded-xl bg-white p-3"}>
                            <p className="text-red-500 font-bold">Atentie! Oferim o singura editare gratuita</p>
                            {
                                selectedJobTitle != null
                                    ? <p>
                                        Cost credite total: {!jobPosting!.hasBeenEdited ? 0 : selectedJobTitle.data.jobPostCreditCost} credite
                                    </p>
                                    : <p className={"text-sm text-gray-400"}>
                                        Selecteaza numele jobului pentru a afisa costul creditelor
                                    </p>
                            }
                            <button
                                disabled={!selectedJobTitle?.hasCredits ?? true}
                                onClick={!selectedJobTitle?.hasCredits ? navigateToShop : saveJob}
                                className={"flex justify-center items-center w-full p-2 bg-accent-main-500 my-2 rounded-lg text-white disabled:bg-gray-200 disabled:text-gray-400"}>
                                {
                                    selectedJobTitle?.hasCredits ? "Salveaza" : "Spre magazin"
                                }
                            </button>
                        </div>
                        <div className={"w-full"}>
                            <div className={"w-full rounded-xl bg-white p-3"}>
                                <div
                                    className={`transition-all duration-500 overflow-hidden max-h-0`}>
                                    <h1 className={`flex flex-row justify-start items-center gap-2 text-3xl border-b mb-3 py-3 text-accent-main-500 border-accent-main-500 transition-opacity duration-500 transform ${isSavingJob ? "opacity-0" : "opacity-100"}`}>
                                        <MdPostAdd/>
                                        Creaza un anunt
                                    </h1>
                                </div>
                                {
                                    isLoadingJob
                                        ? <></>
                                        : <div
                                            className={`duration-300 flex flex-col gap-5 overflow-hidden ${isSavingJob ? "max-h-[120px]" : "max-h-[1000px]"}`}
                                            style={{transition: "max-height 0.5s ease"}}>
                                            <div className={"flex justify-between bg-gray-100 rounded-lg p-5"}>
                                                <div>
                                                    <p className={"text-2xl text-accent-main-500 font-bold"}>
                                                        {jobPosting?.positionTitle}
                                                    </p>
                                                    <div className={"text-gray-400"}>
                                                        Cod COR asociat: <b
                                                        className={"text-black"}>{jobPosting?.corCode}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"flex flex-col gap-5 bg-gray-100 rounded-lg p-5"}>
                                                <p className={"text-2xl font-bold"}>
                                                    Doresc sa angajez:
                                                </p>
                                                <div className={"flex gap-4 justify-center items-center"}>
                                                    <div className="flex space-x-2 justify-center p-4">
                                                        <button
                                                            className={`px-4 py-2 rounded-lg font-semibold transition-colors  bg-accent-main-500 text-white`}
                                                        >
                                                            {convertToDisplayText(request.jobType)}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"flex flex-col gap-5 bg-gray-100 rounded-lg p-5"}>
                                                <p className={"text-2xl font-bold"}>
                                                    Numarul de persoane pe acest post:
                                                </p>
                                                <div className={"flex gap-4 justify-center items-center"}>
                                                    <button onClick={() => updatePersons(-1)}
                                                            className={"px-4 py-2 bg-accent-main-500 text-white rounded-xl"}>
                                                        -
                                                    </button>
                                                    <div
                                                        className={"bg-white w-[100px] flex items-center justify-center border rounded-xl px-5 py-3"}>{request.employeesNumberRequired}</div>
                                                    <button onClick={() => updatePersons(1)}
                                                            className={"px-4 py-2 bg-accent-main-500 text-white rounded-xl"}>
                                                        +
                                                    </button>
                                                </div>
                                            </div>

                                            <div className={"flex flex-col bg-gray-100 rounded-lg gap-5 p-5"}>
                                                <p className={"text-2xl font-bold"}>
                                                    Experienta necesara:
                                                </p>
                                                <div className="flex space-x-2 justify-center p-4">
                                                    {Object.values(ExperienceLevels).map((level) => (
                                                        <button
                                                            key={level}
                                                            onClick={() => selectExperienceLevel(level)}
                                                            className={`px-4 py-2 rounded-lg font-semibold transition-colors 
                                                    ${request.experienceLevel === level ? 'bg-accent-main-500 text-white' : 'bg-gray-300 text-gray-800'}
                                                    hover:bg-accent-main-400 hover:text-white`}
                                                        >
                                                            {convertToName(level)}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className={"flex flex-col bg-gray-100 rounded-lg gap-5 p-5"}>
                                                <p className={"text-2xl font-bold"}>
                                                    Alte detalii:
                                                </p>
                                                <div className={"flex justify-center items-center gap-5"}>
                                                    <p>Data de start: </p>
                                                    <div className="flex justify-center">
                                                        <DatePicker
                                                            className={"p-2 rounded-lg border text-center outline-none"}
                                                            selected={request.startTimeTimestamp === 0 ? new Date() : new Date(request.startTimeTimestamp)}
                                                            onChange={(date) => handleDateStartChange(date)}
                                                            dateFormat="dd MMM yyyy"
                                                            minDate={jobPosting?.startingTime === 0 ? new Date() : new Date(jobPosting?.startingTime ?? 0)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={"flex justify-center items-center gap-5"}>
                                                    <p>Salariul pe luna: </p>
                                                    <div className="flex justify-center">
                                                        <input value={request.monthlySalary}
                                                               onChange={(e) => handleSalaryChange(e.target.value)}
                                                               type={"number"}
                                                               className={"p-3 rounded-lg border bg-white outline-none"}/>
                                                    </div>
                                                </div>

                                                <div className={"flex justify-center items-center gap-5"}>
                                                    <p>Zile de lucru pe saptamana: </p>
                                                    <div className="flex justify-center gap-2">
                                                        <input value={request.daysPerWeek}
                                                               onChange={(e) => handleDaysPerWeek(e.target.value)}
                                                               type={"number"}
                                                               min={1}
                                                               max={7}
                                                               className={"p-3 rounded-lg border bg-white outline-none"}/>
                                                    </div>
                                                </div>

                                                <div className={"flex justify-center items-center gap-5"}>
                                                    <p>Ore de lucru pe zi: </p>
                                                    <div className="flex justify-center gap-2">
                                                        {
                                                            [4, 6, 8, 10, 12].map((el) =>
                                                                <div
                                                                    onClick={() => handleHoursClick(el)}
                                                                    className={`cursor-pointer rounded-lg ${request.hoursPerDay === el ? "bg-accent-main-500 text-white" : "bg-gray-300"} py-1.5 px-3`}>
                                                                    {el}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                isSavingJob
                                    ? <p className={"p-5 flex justify-center gap-2 items-center text-lg text-gray-400"}>
                                        <Loading color={"fill-gray-400"}/>
                                        Se salveaza pozitia
                                    </p>
                                    : <></>
                            }
                        </div>
                    </div>
            }
            {
                isPopupShowing
                    ?
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg w-1/3 h-fit p-6 relative">
                            <div className="flex mb-4">
                                <h2 className="text-center w-full text-3xl font-semibold">Job postat cu
                                    success</h2>
                            </div>

                            <div className="text-gray-400 flex flex-col justify-center items-center">
                                <p className={"mb-10"}>Felicitări! Jobul tău a fost postat cu succes! 🎉</p>
                                <div className={"flex flex-col"}>
                                    <Link
                                        to={ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.viewJobEntry + jobPosting?.slug}
                                        className={"mb-2 px-4 py-2 rounded-xl bg-accent-main-500 text-white"}
                                    >
                                        Vezi jobul postat
                                    </Link>
                                    <Link
                                        to={ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.browse}>
                                        Vezi lista ta de joburi
                                    </Link>
                                    <Link to={ApplicationRoutes.dashboard + DashboardUserRouter.shop}>
                                        Achizitioneaza credite
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    );
}