import {AxiosResponse} from "axios";
import {apiRoutes} from "../../../../environment/EnvironmentRoutes";
import UserResponse from "../models/UserResponse";
import axiosInstance from "../../../../functionals/auth_logic/AxiosInterceptors";

export default class HeaderService {
    async logoutUser(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axiosInstance.post<UserResponse>(apiRoutes.logout);
        return response.data;
    }
}