import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {IoChevronBack, IoLogOut, IoNotifications, IoSettingsOutline} from "react-icons/io5";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import PrivateAccessRoute from "../../commons/functionals/auth_logic/route_permitters/PrivateAccessRoute";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import DashboardPage from "./components/DashboardPage";
import {BiBookReader} from "react-icons/bi";
import {CiShop, CiViewList} from "react-icons/ci";
import {IoIosMore, IoIosPersonAdd} from "react-icons/io";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import JobsRouter from "../../routers/JobsRouter";
import DashboardOptionInterface from "./interfaces/DashboardOptionInterface";
import {AuthContext} from "../../commons/functionals/auth_logic/AuthContext";
import {FaHome} from "react-icons/fa";
import {PiCaretUpDown} from "react-icons/pi";
import HeaderService from "../../commons/component/header/functionals/services/HeaderService";
import {FaRegUser} from "react-icons/fa6";
import FirmDetails from "../firm-details/FirmDetails";
import {AiOutlineCopyrightCircle} from "react-icons/ai";
import Store from "../store/Store";
import JobRootPage from "../jobs/JobsRootPage";

export default function Dashboard() {
    const upperOptions: DashboardOptionInterface[] = [
        {
            icon: <FaHome/>,
            label: "Home",
            link: ApplicationRoutes.dashboard,
            submenu: null,
        },
        {
            icon: <BiBookReader/>,
            label: "Joburi",
            link: ApplicationRoutes.dashboard + DashboardUserRouter.jobs,
            submenu: [{
                icon: <IoIosPersonAdd/>,
                label: "Creaza un anunt",
                link: JobsRouter.create,
                submenu: null,
            }, {
                icon: <CiViewList/>,
                label: "Anunturile mele",
                link: JobsRouter.browse,
                submenu: null,
            }],
        }, {
            icon: <CiShop/>,
            label: "Magazin",
            link: ApplicationRoutes.dashboard + DashboardUserRouter.shop,
            submenu: null,
        },
    ];

    const lowerOptions: DashboardOptionInterface[] = [
        {
            icon: <IoIosMore/>,
            label: "Detalii Firma",
            link: ApplicationRoutes.dashboard + DashboardUserRouter.details,
            submenu: null,
        },
        {
            icon: <IoNotifications/>,
            label: "Notificari",
            link: ApplicationRoutes.dashboard + DashboardUserRouter.notifications,
            submenu: null
        },
        {
            icon: <IoSettingsOutline/>,
            label: "Setari",
            link: ApplicationRoutes.dashboard + DashboardUserRouter.settings,
            submenu: null
        },
    ];
    const {user, setUser} = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [isUserOpened, setIsUserOpened] = useState<boolean>(false);
    const dropdownRefUser = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    const closeDropdown = (event: MouseEvent) => {
        if (dropdownRefUser.current && !dropdownRefUser.current.contains(event.target as Node)) {
            setIsUserOpened(false);
        }
    };

    const toggleDropdownUser = () => {
        setIsUserOpened(!isUserOpened);
    };

    function logout() {
        new HeaderService().logoutUser().then(_ => {
                setUser(null)
                setIsUserOpened(false);
                navigate(ApplicationRoutes.login);
            }
        );
    }

    return (
        <div className={"flex-col md:flex-row flex h-full pt-[60px]"}>
            <div className="w-[400px] h-full transition-transform">
                <div className="h-full flex flex-col px-3 py-4 overflow-y-auto bg-gray-50">
                    <ul className={"justify-center items-center font-main-medium flex-row md:flex-col flex gap-2"}>
                        {
                            upperOptions.map((option, i) =>
                                <li key={i} className={"w-full"}>
                                    <Link to={option.link}
                                          className={`flex p-2 rounded-lg hover:bg-text-main-color-100 ${location.pathname === option.link ? 'bg-accent-main-100 text-accent-main-600' : 'text-text-main-color-500'} group`}>
                                        <div className={"w-full flex justify-start items-center"}>
                                            {option.icon}
                                            <span className="md:flex ms-3 group-hover:text-text-main-color-950">
                                                        {option.label}
                                                    </span>
                                        </div>
                                        {
                                            option.submenu != null
                                                ? <p className={"flex justify-center items-center"}>
                                                    <IoChevronBack
                                                        className={`${location.pathname.includes(option.link) ? "-rotate-90" : "rotate-180"}`}/>
                                                </p>
                                                : <></>
                                        }
                                    </Link>
                                    <ul className={`ml-5 flex-col ${location.pathname.includes(option.link) ? "flex" : "hidden"}`}>
                                        {
                                            option.submenu && option.submenu.map((suboption, i) =>
                                                <li key={i} className={"w-full mt-2"}>
                                                    <Link to={option.link + suboption.link}
                                                          className={`flex p-2 justify-start items-center rounded-lg hover:bg-text-main-color-100 ${location.pathname === (option.link + suboption.link) ? 'bg-accent-main-100 text-accent-main-500' : 'text-text-main-color-500'} group`}>
                                                        {suboption.icon}
                                                        <span
                                                            className={`hidden md:flex ms-3 group-hover:text-text-main-color-950`}>{suboption.label}</span>
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </li>
                            )
                        }
                    </ul>
                    <div className={"flex-grow"}></div>
                    <ul className={"flex gap-2 flex-col"}>
                        {
                            lowerOptions.map(
                                (el, i) =>
                                    <li key={i}>
                                        <Link to={el.link}
                                              className={"cursor-pointer text-gray-800 flex flex-row gap-2 justify-start items-center hover:text-gray-900"}>
                                            {el.icon}
                                            <p>{el.label}</p>
                                        </Link>
                                    </li>
                            )
                        }
                    </ul>
                    <hr className={"w-full border-gray-400 my-3"}/>
                    <div
                        onClick={toggleDropdownUser}
                        className={`transition-all bg-white rounded-lg shadow-lg flex flex-col px-3 pb-3 overflow-hidden ${isUserOpened ? "h-[130px]" : "h-[70px]"}`}>
                        <div className="flex-grow">
                            <div className="pt-3 cursor-pointer flex flex-row gap-3 justify-center items-center">
                                <div
                                    className="w-[50px] h-[40px] p-2 flex justify-center items-center rounded-full bg-gray-200">
                                    <FaRegUser/>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="text-md">{user?.firstName ?? ""} {user?.lastName ?? ""}</div>
                                    <div className="text-sm text-gray-500">{user?.email ?? ""}</div>
                                </div>
                                <PiCaretUpDown className="w-[20px] h-[20px]"/>
                            </div>
                        </div>

                        {isUserOpened && (
                            <ul className="pt-3">
                                <li
                                    className="flex gap-2 text-error rounded-lg cursor-pointer px-4 py-2 text-sm hover:bg-gray-100"
                                    onClick={logout}>
                                    <IoLogOut className="w-5 h-5"/>
                                    <p>Log out</p>
                                </li>
                            </ul>
                        )}
                    </div>
                    <p className={"text-xs text-gray-500 pt-3 flex justify-center items-center gap-1"}>
                        <AiOutlineCopyrightCircle/> {new Date().getFullYear()} Worklink
                        v{process.env.REACT_APP_DASHBOARD_VERSION ?? "release"}
                    </p>
                </div>
            </div>
            <div className="p-4 w-full h-full overflow-y-auto bg-gray-100">
                <Routes>
                    <Route path={DashboardUserRouter.root}
                           element={
                               <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                   <DashboardPage/>
                               </PrivateAccessRoute>
                           }/>
                    <Route path={DashboardUserRouter.shop}
                           element={
                               <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                   <Store/>
                               </PrivateAccessRoute>
                           }/>
                    <Route path={DashboardUserRouter.details}
                           element={
                               <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                   <FirmDetails/>
                               </PrivateAccessRoute>
                           }/>
                    <Route path={DashboardUserRouter.jobs + JobsRouter.entrance}
                           element={
                               <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                   <JobRootPage/>
                               </PrivateAccessRoute>
                           }/>
                </Routes>
            </div>
        </div>
    );
}