enum ExperienceLevels {
    None = "None",
    Junior = "Junior",
    Mid = "Mid",
    Expert = "Expert",
    Senior = "Senior"
}

const convertToName = (level: ExperienceLevels) => {
    switch (level) {
        case ExperienceLevels.None:
            return "0 ani";
        case ExperienceLevels.Junior:
            return "0 - 2 ani";
        case ExperienceLevels.Mid:
            return "3 - 4 ani";
        case ExperienceLevels.Expert:
            return "5 - 6 ani";
        case ExperienceLevels.Senior:
            return "6+ ani";
    }
}

export {ExperienceLevels, convertToName};