import RouteName from "./models/ApiRouteInteface";

const baseApiRoute = process.env.REACT_APP_API_BASE_LINK;

const apiRoutes: RouteName = {
    user: baseApiRoute + '/v1/auth/user',
    login: baseApiRoute + '/v1/auth/sign-in',
    register: baseApiRoute + '/v1/auth/sign-up/firm',
    logout: baseApiRoute + '/v1/auth/sign-out',
    verifyToken: baseApiRoute + '/v1/auth/verify-token?token=TOKEN',
    verifyCui: baseApiRoute + '/v1/auth/check-cui?cui=',

    updateOnboarding: baseApiRoute + '/v1/onboarding/next/firm/SLUG',
    getCaenCode: baseApiRoute + '/v1/onboarding/search/caen-code?code=',

    getFirmDetails: baseApiRoute + '/v1/firm/SLUG/details',
    getFirmTransactions: baseApiRoute + '/v1/transactions/firm/SLUG',
    checkFirmCredits: baseApiRoute + '/v1/firm/SLUG/has-credits/AMOUNT',

    createJob: baseApiRoute + '/v1/jobs/firm/SLUG',
    updateJob: baseApiRoute + '/v1/jobs/JOB_SLUG/firm/SLUG',
    getJobName: baseApiRoute + '/v1/jobs/title?name=',
    getMyJobs: baseApiRoute + '/v1/jobs/mine',
    getJobs: baseApiRoute + '/v1/jobs/',

    getProducts: baseApiRoute + '/v1/products'
};

export {apiRoutes};