import React, {useEffect, useState} from "react";
import OnboardingStepProps from "../interfaces/OnboardingStepProps";
import {IoClose} from "react-icons/io5";
import OnboardingService from "../../../services/OnboardingService";
import CAENCode from "../../../models/CAENCode";
import Loading from "../../../commons/component/loading/views/Loading";

const OnboardingStep2: React.FC<OnboardingStepProps> = ({setValue}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isLoadingCAEN, setIsLoadingCAEN] = useState<boolean>(false);
    const [caenSearchResults, setCaenSearchResults] = useState<CAENCode[]>([]);
    const onboardingService = new OnboardingService();
    const debounceDelay = 500;  // debounce delay in milliseconds

    useEffect(() => {
        setValue(selectedItems.join("|"));
    }, [selectedItems]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleItemClick = (item: string) => {
        setSelectedItems((prevItems) =>
            selectedItems.includes(item)
                ? selectedItems.filter((p) => p !== item)
                : [...prevItems, item]
        );
    };

    useEffect(() => {
        if (searchTerm.trim() === "") return;

        const timerId = setTimeout(() => {
            getCaenCodes();
        }, debounceDelay);

        return () => clearTimeout(timerId);
    }, [searchTerm]);

    const getCaenCodes = () => {
        setIsLoadingCAEN(true);
        onboardingService.getCAENCode(searchTerm)
            .then((result) => setCaenSearchResults(result))
            .catch((_) => {
            })
            .finally(() => setIsLoadingCAEN(false));
    }

    return (
        <div className="w-full h-full p-5 flex flex-col justify-center items-center">
            <div className="max-w-[500px] w-full">
                <label className="mt-3 text-sm">Coduri CAEN secundare</label>
                <div className="mb-4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Cauta coduri CAEN"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"
                    />
                </div>
                {searchTerm.trim().length !== 0 && (
                    <div className="mb-4">
                        {isLoadingCAEN ? (
                            <Loading color="fill-accent-main-500"/>
                        ) : (
                            caenSearchResults.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleItemClick(item.code)}
                                    className="cursor-pointer bg-gray-100 py-2.5 px-5 rounded-lg mb-1 flex gap-2"
                                >
                                    {item.code}
                                    <p> - </p>
                                    {item.description}
                                </div>
                            ))
                        )}
                    </div>
                )}
                <div className="mt-4">
                    {selectedItems.length > 0 && (
                        <div>
                            <h3 className="text-lg font-bold mb-2">Coduri CAEN secundare selectate:</h3>
                            <div className="flex flex-wrap gap-2">
                                {selectedItems.map((item, index) => (
                                    <span
                                        key={index}
                                        onClick={() => handleItemClick(item)}
                                        className="cursor-pointer bg-accent-main-500 text-white py-1 px-3 rounded-full gap-2 flex justify-center items-center"
                                    >
                                        {item}
                                        <IoClose/>
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OnboardingStep2;
