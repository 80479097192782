export default class ApplicationRoutes {
    static root = "/";
    static login = "/sign-in";
    static register = "/sign-up";
    static verify = "/verify/:token";
    static verifyEntry = "/verify/";
    static dashboard = "/dashboard";
    static dashboardAdminRoot = "/dashboard/admin";
    static payment = "/pay";
    static any = "*";
}