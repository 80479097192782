import React from "react";

export default function OnboardingComplete() {
    return (
        <div className={"w-full h-full flex flex-col justify-center items-center"}>
            <div className={"max-w-[500px] w-full"}>
                <p className={"py-4 text-center"}>
                    Felicitari! Ai terminat procesul de onboard
                </p>
            </div>
        </div>
    )
}