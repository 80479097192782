import axios, {AxiosResponse} from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import Firm from "../models/Firm";
import {FirmTransaction} from "../models/FirmTransaction";

export default class FirmService {

    async getDetails(firmSlug: string): Promise<Firm> {
        const response: AxiosResponse<Firm> = await axios.get<Firm>(apiRoutes.getFirmDetails.replaceAll("SLUG", firmSlug), {withCredentials: true});
        return response.data;
    }

    async getFirmTransactions(firmSlug: string): Promise<FirmTransaction[]> {
        const response: AxiosResponse<FirmTransaction[]> = await axios.get<FirmTransaction[]>(apiRoutes.getFirmTransactions.replaceAll("SLUG", firmSlug), {withCredentials: true});
        return response.data;
    }

}