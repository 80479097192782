import React from "react";
import OnboardingStepProps from "../interfaces/OnboardingStepProps";

const OnboardingStep3: React.FC<OnboardingStepProps> = ({setValue}) => {

    return (
        <div className={"w-full h-full flex flex-col justify-center items-center"}>
            <div className={"max-w-[500px] w-full"}>
                <p className={"py-4 text-center"}>
                    Cati angajati are firma ta?
                </p>
                <div>
                    <label className={"mt-3 text-sm"}></label>
                    <input type="number"
                           required={true}
                           step={1}
                           min={0}
                           onChange={(e) => setValue(e.target.value)}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                </div>
            </div>
        </div>
    )
}

export default OnboardingStep3;