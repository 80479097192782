import React, {FormEvent, useContext, useState} from "react";
import LoginService from "../../services/LoginService";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Loading from "../../commons/component/loading/views/Loading";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import {AuthContext} from "../../commons/functionals/auth_logic/AuthContext";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";

export default function LoginPage() {

    const {setUser} = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);
    const navigate = useNavigate();
    const [next] = useSearchParams();

    function login(event: FormEvent) {
        event.preventDefault();
        setComponentLoading(true);

        new LoginService().login(email, password).then((response) => {
            setUser(response.data);
            navigate(next.get("next") ?? (response.data.role === UserRoles.ROLE_SUPER_ADMIN ? ApplicationRoutes.dashboardAdminRoot : ApplicationRoutes.dashboard));
        }).catch((error) => {
            setError((error.response ?? {data: {message: ''}}).data.message);
        }).finally(() => setComponentLoading(false));
    }

    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            <div className={"w-full md:w-1/3 p-5"}>
                <h1 className={"pb-4 text-3xl text-center font-main-bold"}>Portal logare</h1>
                <div className="mb-6">
                    <form onSubmit={login} className={"flex justify-center items-center flex-col"}>
                        <input type="text" placeholder="Email"
                               required={true}
                               onChange={(e) => setEmail(e.target.value)}
                               className="mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-accent-main-500 focus:border-accent-main-500 block w-full p-2.5"/>
                        <input type="password" placeholder="Password"
                               required={true}
                               onChange={(e) => setPassword(e.target.value)}
                               className="mt-5 mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-accent-main-500 focus:border-accent-main-500 block w-full p-2.5"/>
                        <div className={"mb-5 text-error"}>
                            {error ?? ""}
                        </div>
                        <button type="submit"
                                disabled={componentLoading}
                                className={"w-32 text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-main-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all flex justify-center items-center"}>
                            {!componentLoading ? "Login" : <Loading/>}
                        </button>
                        <Link to={ApplicationRoutes.register}
                              className={"mt-2 text-accent-main-500 font-main-medium flex justify-center items-center w-full text-center"}>
                            <span>Creaza un cont</span>
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    );
}