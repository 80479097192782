import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "../../commons/component/header/views/Header";
import {AuthProvider} from "../../commons/functionals/auth_logic/AuthContext";
import Home from "../home/Home";
import LoginPage from "../login/LoginPage";
import RegisterPage from "../register/RegisterPage";
import VerifyTokenPage from "../verify-token/VerifyTokenPage";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import PrivateAccessRoute from "../../commons/functionals/auth_logic/route_permitters/PrivateAccessRoute";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import DashboardAdminRouter from "../../routers/DashboardAdminRouter";
import Dashboard from "../dashboard/Dashboard";
import PaymentGateway from "../store/PaymentGateway";

function App() {

    return (
        <div className={"font-main-light"}>
            <AuthProvider>
                <BrowserRouter>
                    <Header/>
                    <div className={"h-screen"}>
                        <Routes>
                            <Route path={ApplicationRoutes.root} element={<Home/>}/>
                            <Route path={ApplicationRoutes.login} element={<LoginPage/>}/>
                            <Route path={ApplicationRoutes.register} element={<RegisterPage/>}/>
                            <Route path={ApplicationRoutes.verify} element={<VerifyTokenPage/>}/>
                            <Route path={ApplicationRoutes.dashboard + DashboardUserRouter.entrance} element={
                                <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                    <Dashboard/>
                                </PrivateAccessRoute>
                            }/>
                            <Route path={ApplicationRoutes.payment} element={
                                <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                                    <PaymentGateway/>
                                </PrivateAccessRoute>
                            }/>
                            <Route path={ApplicationRoutes.dashboardAdminRoot + DashboardAdminRouter.entrance} element={
                                <PrivateAccessRoute permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                                    <div>DASHBOARD ADMIN</div>
                                </PrivateAccessRoute>
                            }/>
                        </Routes>
                    </div>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default App;
