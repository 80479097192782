import {useContext, useState} from "react";
import {AuthContext} from "../../commons/functionals/auth_logic/AuthContext";
import OnboardingStep1 from "./components/OnboardingStep1";
import OnboardingSteps from "../../models/enums/OnboardingSteps";
import OnboardingComplete from "./components/OnboardingComplete";
import OnboardingStep2 from "./components/OnboardingStep2";
import OnboardingStep3 from "./components/OnboardingStep3";
import {IoChevronForward} from "react-icons/io5";
import OnboardingService from "../../services/OnboardingService";
import Loading from "../../commons/component/loading/views/Loading";

export default function Onboarding() {
    const {user} = useContext(AuthContext);
    const [onboardingStepPage, setOnboardingStepPage] = useState<OnboardingSteps>(user?.onboardStep ?? OnboardingSteps.STEP_1);
    const [caenCode, setCaenCode] = useState<string>("");
    const [secondaryCaenCodes, setSecondaryCaenCodes] = useState<string>("");
    const [employeeNumber, setEmployeeNumber] = useState<string>("0");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onboardService = new OnboardingService();


    const buildOnboardingPage = () => {
        switch (onboardingStepPage) {
            case OnboardingSteps.STEP_1:
                return <OnboardingStep1 setValue={setCaenCode}/>;

            case OnboardingSteps.STEP_2:
                return <OnboardingStep2 setValue={setSecondaryCaenCodes}/>;

            case OnboardingSteps.STEP_3:
                return <OnboardingStep3 setValue={setEmployeeNumber}/>;

            case OnboardingSteps.DONE:
                return <OnboardingComplete/>;
        }
    }

    const changePage = (): OnboardingSteps => {
        switch (onboardingStepPage) {
            case OnboardingSteps.STEP_1:
                return OnboardingSteps.STEP_2;

            case OnboardingSteps.STEP_2:
                return OnboardingSteps.STEP_3;

            case OnboardingSteps.STEP_3:
                return OnboardingSteps.DONE;

            default:
                return OnboardingSteps.DONE;
        }
    }

    const onboard = () => {
        setIsLoading(true);
        onboardService.onboard(user?.firmSlug ?? "", {
            mainCaenCode: caenCode,
            secondaryCaenCodes: secondaryCaenCodes,
            employeeNumber: parseInt(employeeNumber)
        })
            .then((step) => {
                setOnboardingStepPage(step);
            })
            .catch((_) => {
            }).finally(() => setIsLoading(false));
    }

    return (
        <div className={"pt-[100px] h-screen w-full flex flex-col bg-white"}>
            {buildOnboardingPage()}
            <div
                className={`w-full h-[100px] flex ${onboardingStepPage !== OnboardingSteps.DONE ? "justify-end" : "justify-center"} p-5`}>
                {
                    isLoading ?
                        <Loading color={"fill-accent-main-500"}/>
                        : <button
                            className={"bg-accent-main-500 rounded-lg text-white py-2.5 px-5 flex items-center justify-center gap-2"}
                            onClick={() =>
                                onboardingStepPage === OnboardingSteps.STEP_3 ?
                                    onboard() :
                                    onboardingStepPage === OnboardingSteps.DONE ?
                                        window.location.reload() :
                                        setOnboardingStepPage(changePage())}>
                            {onboardingStepPage === OnboardingSteps.STEP_3 ? "Finalizeaza" : onboardingStepPage === OnboardingSteps.DONE ? "Exploreaza dashboard" : "Urmatorul pas"}
                            <IoChevronForward/>
                        </button>
                }
            </div>
        </div>
    )
}