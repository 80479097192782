import React, {useEffect, useState} from "react";
import JobPosting from "../../../models/JobPosting";
import JobsService from "../../../services/JobsService";
import {toast} from "react-hot-toast";
import Loading from "../../../commons/component/loading/views/Loading";
import {convertToName} from "../../../models/enums/ExperienceLevels";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import JobsRouter from "../../../routers/JobsRouter";
import {MdNumbers} from "react-icons/md";
import {CiCircleList} from "react-icons/ci";
import {BsSuitcaseLgFill} from "react-icons/bs";
import {TbStatusChange} from "react-icons/tb";
import {SiStatuspal} from "react-icons/si";
import {JobType} from "../../../models/enums/JobType";

export default function ListJobsPage() {

    const [isLoadingJobs, setIsLoadingJobs] = useState<boolean>(false);
    const [jobPostings, setJobPostings] = useState<JobPosting[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filterJobType, setFilterJobType] = useState<JobType>(JobType.none);
    const jobsService = new JobsService();
    const navigator = useNavigate();

    useEffect(() => {
        getJobs()
    }, [currentPage, filterJobType]);

    const getJobs = () => {
        setIsLoadingJobs(true);
        jobsService.getMyJobs(currentPage, filterJobType)
            .then((response) => {
                setJobPostings(response.content);
                setTotalPages(response.totalPages);
            })
            .catch((_) => toast.error("Incearca din nou"))
            .finally(() => setIsLoadingJobs(false));
    }

    const nextPage = () => {
        if (currentPage + 1 >= totalPages) return;
        setCurrentPage(currentPage + 1);
    }

    const prevPage = () => {
        if (currentPage - 1 < 0) return;
        setCurrentPage(currentPage - 1);
    }

    return (
        <div className={"flex flex-col gap-3"}>
            <div className={"rounded-lg bg-white p-1.5"}>
                <h1 className={`flex flex-row justify-start items-center gap-2 text-3xl border-b mb-3 py-3 text-accent-main-500 border-accent-main-500`}>
                    <CiCircleList/>
                    Anunturile mele
                </h1>
                <div className={"p-2 overflow-y-auto flex gap-2"}>
                    <span className={"py-0.5 p-2"}>Tipul Anuntului: </span>
                    <button
                        onClick={() => setFilterJobType(filterJobType === JobType.extern ? JobType.none : JobType.extern)}
                        className={`py-0.5 p-2 rounded-full ${filterJobType === JobType.extern ? "bg-accent-main-500 text-white" : "bg-gray-200 text-black"}`}>
                        Import
                    </button>
                    <button
                        onClick={() => setFilterJobType(filterJobType === JobType.intern ? JobType.none : JobType.intern)}
                        className={`py-0.5 p-2 rounded-full ${filterJobType === JobType.intern ? "bg-accent-main-500 text-white" : "bg-gray-200 text-black"}`}>
                        Recrutari interne
                    </button>
                </div>
            </div>
            {
                isLoadingJobs
                    ? <div className={"w-full flex justify-center items-center p-4"}><Loading
                        color={"fill-accent-main-500"}/></div>
                    : <>
                    <div className={"h-full flex flex-col gap-3"}>
                            {
                                jobPostings.map((jobPosting, i) => (
                                    <div key={i}
                                         onClick={() => navigator(ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.viewJobEntry + jobPosting.slug)}
                                         className={"cursor-pointer flex items-center bg-white rounded-lg p-2"}>
                                        <div className={"w-full"}>
                                            <p className={"text-sm text-gray-400 flex gap-1 justify-start items-center"}>
                                                <BsSuitcaseLgFill/>
                                                Pozitie:
                                            </p>
                                            <p className={"text-xl"}>{jobPosting.positionTitle}</p>
                                        </div>
                                        <div className={"w-full"}>
                                            <p className={"text-sm text-gray-400 flex gap-1 justify-start items-center"}>
                                                <MdNumbers/>
                                                Cod COR pozitie:
                                            </p>
                                            <p className={"text-xl"}>{jobPosting.corCode}</p>
                                        </div>
                                        <div className={"w-full"}>
                                            <p className={"text-sm text-gray-400 flex gap-1 justify-start items-center"}>
                                                <TbStatusChange/>
                                                Nivel experienta:
                                            </p>
                                            <p className={"text-xl"}>{convertToName(jobPosting.experienceLevel)}</p>
                                        </div>
                                        <div className={"w-full"}>
                                            <p className={"text-sm text-gray-400 flex gap-1 justify-start items-center"}>
                                                <SiStatuspal/>
                                                Salariu lunar oferit:
                                            </p>
                                            <p className={"text-xl"}>{jobPosting.monthlySalary}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={"flex justify-center items-center gap-3 p-2 rounded-xl"}>
                            <button
                                disabled={currentPage <= 0}
                                onClick={prevPage}
                                className={"disabled:hover:border-gray-300 disabled:hover:bg-transparent disabled:border-gray-300 disabled:text-gray-500 py-1 px-2 text-sm border border-accent-main-500 text-accent-main-500 hover:bg-accent-main-500 rounded-lg hover:text-white"}>
                                Pagina precendenta
                            </button>
                            {
                                Array.from({length: totalPages > 5 ? 5 : totalPages}, (_, index) => index + 1).map((item, i) => (
                                    <div key={i}
                                         className={"border text-sm border-accent-main-500 text-accent-main-500 px-2.5 py-1 rounded-md" + (currentPage === i ? " bg-accent-main-500 text-white" : "")}>
                                        {item}
                                    </div>
                                ))
                            }
                            <button
                                disabled={currentPage + 1 >= totalPages}
                                onClick={nextPage}
                                className={"disabled:hover:border-gray-300 disabled:hover:bg-transparent disabled:border-gray-300 disabled:text-gray-500 py-1 px-2 text-sm border border-accent-main-500 text-accent-main-500 hover:bg-accent-main-500 rounded-lg hover:text-white"}>
                                Pagina urmatoare
                            </button>
                        </div>
                    </>
            }
        </div>
    );
}