import {useContext} from 'react';
import {AuthContext} from '../AuthContext';
import RedirectToLoginPage from "../../../../components/login/RedirectToLoginPage";
import AccessDenied from "../../../../components/login/AccessDenied";
import UserRoles from "../../../component/header/functionals/models/UserRoles";
import Onboarding from "../../../../components/onboarding/Onboarding";

function PrivateAccessRoute({children, permittedRole}: {
    children: JSX.Element,
    permittedRole: UserRoles[]
}) {
    const {user} = useContext(AuthContext);

    return user == null ?
        <RedirectToLoginPage/> :
        !permittedRole.includes(user.role) ?
            <AccessDenied/> :
            !user.hasOnboarded ?
                <Onboarding/> : children;
}

export default PrivateAccessRoute;
