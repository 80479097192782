enum JobType {
    intern = "intern",
    extern = "extern",
    none = ""
}

const convertToDisplayText = (jobType: JobType) => {
    switch (jobType) {
        case JobType.intern:
            return "Caut o persoana din intern";

        case JobType.extern:
            return "Doresc sa regrutez din afara tarii";

        default:
            return "Tip de anunt nerecunoscut";
    }
}

export {JobType, convertToDisplayText};