import FirmService from "../../../services/FirmService";
import React, {useContext, useEffect, useState} from "react";
import Firm from "../../../models/Firm";
import {AuthContext} from "../../../commons/functionals/auth_logic/AuthContext";
import Loading from "../../../commons/component/loading/views/Loading";
import OnboardingStepProps from "../interfaces/OnboardingStepProps";

const OnboardingStep1: React.FC<OnboardingStepProps> = ({setValue}) => {

    const {user} = useContext(AuthContext);
    const firmService = new FirmService();
    const [firm, setFirm] = useState<Firm | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        firmService.getDetails(user?.firmSlug ?? "")
            .then((rsp) => {
                setFirm(rsp);
                setValue(rsp.mainCaenCode);
            })
            .catch((_) => {
            })
            .finally(() => setIsLoading(false));
    }, []);

    return (
        <div className={"w-full h-full flex justify-center items-center"}>
            {
                isLoading
                    ? <Loading color={"fill-accent-main"}/>
                    : <div>
                        <p className={"py-4 text-center"}>
                            Pentru firma <b>{firm?.name ?? ""}</b>, cu acest CUI <b>{firm?.cui ?? ""}</b>, am detectat codul
                            CAEN <b>{firm?.mainCaenCode}</b>.
                        </p>
                        <p className={"py-4 text-center"}>
                            Verifica daca codul CAEN este corect ales
                        </p>
                        <div>
                            <label className={"mt-3 text-sm"}>Codul CAEN</label>
                            <input type="text"
                                   required={true}
                                   value={firm?.mainCaenCode ?? ''}
                                   onChange={(e) => setValue(e.target.value)}
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                        </div>
                    </div>
            }
        </div>
    )
}

export default OnboardingStep1;