import {HiBuildingOffice2} from "react-icons/hi2";
import {FaMonument, FaPerson} from "react-icons/fa6";
import {PiIdentificationBadgeLight} from "react-icons/pi";
import {MdFormatListNumbered, MdModeEdit} from "react-icons/md";
import {IoChevronDown, IoPin} from "react-icons/io5";
import React, {useEffect, useState} from "react";
import JobPosting from "../../../models/JobPosting";
import JobsService from "../../../services/JobsService";
import {Link, useLocation, useParams} from "react-router-dom";
import Loading from "../../../commons/component/loading/views/Loading";
import {convertToName, ExperienceLevels} from "../../../models/enums/ExperienceLevels";
import {convertToDisplayText, JobType} from "../../../models/enums/JobType";
import JobsRouter from "../../../routers/JobsRouter";

export default function JobDetailsPage() {

    const [isLoadingJob, setIsLoadingJob] = useState<boolean>(false);
    const [jobPosting, setJobPosting] = useState<JobPosting | null>(null);
    const jobsService = new JobsService();
    const {jobSlug} = useParams();
    const location = useLocation();

    useEffect(() => {
        setIsLoadingJob(true);
        jobsService.getJob(jobSlug ?? "")
            .then((response) => setJobPosting(response))
            .catch(() => {
            })
            .finally(() => setIsLoadingJob(false));
    }, []);


    return (
        <div className={"flex flex-col gap-3"}>
            {
                isLoadingJob ? <Loading/> :
                    <div className={"text-center md:text-start rounded-lg shadow-lg bg-white"}>
                        <div className={"px-5 py-2.5 flex justify-between items-start"}>
                            <div>
                                <h1 className={"text-2xl text-accent-main-500 flex gap-2 items-center tracking-wider"}>
                                    <HiBuildingOffice2/>
                                    {jobPosting?.positionTitle ?? "No position"}
                                </h1>
                                <p className={"text-sm text-gray-500 flex gap-1 items-center tracking-wider"}>
                                    <FaMonument/>
                                    {jobPosting?.corCode ?? "No COR Code"}
                                </p>
                            </div>
                            <Link
                                to={location.pathname + JobsRouter.editEntry}
                                className={"h-fit p-2 rounded-xl bg-accent-main-500 shadow-lg text-white flex gap-1 justify-center items-center"}>
                                <MdModeEdit/>
                                Editeaza
                            </Link>
                        </div>
                        <hr/>
                        <div className="lg:overflow-x-auto scrollbar-hide px-5 py-2.5">
                            <div className={"w-full lg:w-[1800px] flex flex-col lg:flex-row gap-2 lg:gap-5"}>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <PiIdentificationBadgeLight/> Identificator pe platforma
                                    </p>
                                    <p className="text-gray-500 text-sm">{jobPosting?.slug ?? "No slug"}</p>
                                </div>
                                <div className="border-r border-gray-300"/>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <MdFormatListNumbered/> Salar lunar pentru o persoana
                                    </p>
                                    <p className="text-gray-500 text-sm">{jobPosting?.monthlySalary ?? "0"} lei</p>
                                </div>
                                <div className="border-r border-gray-300"/>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <IoChevronDown/>
                                        Numar persoane angajate
                                    </p>
                                    <p className="text-gray-500 text-sm">{jobPosting?.requiredEmployees ?? "0"} persoane</p>
                                </div>
                                <div className="border-r border-gray-300"/>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <IoChevronDown/>
                                        Experienta necesara
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        {convertToName(jobPosting?.experienceLevel ?? ExperienceLevels.None)}
                                    </p>
                                </div>
                                <div className="border-r border-gray-300"/>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <IoPin/> Tipul jobului
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        {convertToDisplayText(jobPosting?.jobType ?? JobType.intern)}
                                    </p>
                                </div>
                                <div className="border-r border-gray-300"/>
                                <div className="w-full lg:w-[300px]">
                                    <p className="text-accent-main-500 text-md flex justify-center md:justify-start items-center gap-1">
                                        <IoPin/> Data inceperii
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        {new Date(jobPosting?.jobType ?? Date.now()).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className={"text-center md:text-start rounded-lg shadow-lg bg-white"}>
                <div className={"px-5 py-2.5"}>
                    <h1 className={"text-2xl text-accent-main-500 flex gap-2 items-center tracking-wider"}>
                        <FaPerson/>
                        Top persoane recomandate
                    </h1>
                </div>
            </div>
        </div>
    );
}