import {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../environment/AppRoutingName";
import {IoBusiness} from "react-icons/io5";
import {AuthContext} from "../../../functionals/auth_logic/AuthContext";


export default function Header() {

    const {user} = useContext(AuthContext);
    const navigate = useNavigate();

    function redirect(path: string) {
        navigate(path);
    }

    return (
        <div
            className={"bg-accent-main-500 fixed text-fullWhite z-20 text-xl transition-all flex flex-row items-center justify-between h-[60px] pr-[30px] py-2 w-full"}>
            <div className={"bg-accent-main-500 h-full w-44 ml-3"}>
                <Link to={ApplicationRoutes.root}
                      className={"h-full w-fit flex justify-center font-main-medium cursor-pointer bg-white rounded-lg"}>
                    <img src="/logo.png" className={"h-full p-0.5"} alt={"logo.png"}/>
                </Link>
            </div>
            {
                user === null ?
                    <div>
                        <p className={"cursor-pointer p-2 font-main-bold hover:shadow-lg hover:bg-white hover:text-accent-main-500 transition-all rounded-lg"}
                           onClick={() => redirect(ApplicationRoutes.login)}>
                            Portal logare
                        </p>
                    </div> :
                    <div>
                        <div className={"flex gap-5 text-sm"}>
                            {
                                <div
                                    className="flex gap-2 items-center justify-center w-fit rounded-md border shadow-sm px-4 py-2 bg-fullWhite text-sm font-main-medium text-accent-main-500">
                                    <IoBusiness className={"scale-125"}/> {user.firmName}
                                </div>
                            }
                        </div>
                    </div>
            }
        </div>
    );
}