import axios, {AxiosResponse} from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import OnboardingSteps from "../models/enums/OnboardingSteps";
import OnboardFirmRequest from "../models/requests/OnboardFirmRequest";
import CAENCode from "../models/CAENCode";

export default class OnboardingService {

    async onboard(firmSlug: string, request: OnboardFirmRequest): Promise<OnboardingSteps> {
        const response: AxiosResponse<OnboardingSteps> = await axios.post<OnboardingSteps>(apiRoutes.updateOnboarding.replaceAll("SLUG", firmSlug), request, {withCredentials: true});
        return response.data;
    }

    async getCAENCode(caenCode: string): Promise<CAENCode[]> {
        const response: AxiosResponse<CAENCode[]> = await axios.get<CAENCode[]>(apiRoutes.getCaenCode + caenCode, {withCredentials: true});
        return response.data;
    }

}