import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import NetopiaRequest from "../../models/requests/NetopiaRequest";
import {IoChevronForward} from "react-icons/io5";
import StoreService from "../../services/StoreService";
import Product from "../../models/Product";
import Loading from "../../commons/component/loading/views/Loading";
import {ProductStoreItemRequest, ProductStoreRequest} from "../../models/requests/ProductStoreRequest";
import {CiShop} from "react-icons/ci";

export default function Store() {
    const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>([]);

    const [order, setOrder] = useState<ProductStoreRequest>({items: []});
    const [totalOrderPrice, setTotalOrderPrice] = useState<number>(0);

    const [currentStoreStep, setCurrentStoreStep] = useState<number>(1);
    const navigate = useNavigate();
    const fakeNetopia: NetopiaRequest = {
        message: null,
        linkToSend: "www.link.com",
        env_key: "key",
        data: "data"
    }
    const storeSteps = [1, 2];
    const storeService = new StoreService();

    useEffect(() => {
        setIsLoadingProducts(true);
        storeService.getProducts()
            .then((response) => {
                let initialProducts: Product[] = response.content.map((product: Product) => ({
                    ...product,
                    amount: 0,
                }));
                setProducts(initialProducts);
            })
            .catch((_) => {
            })
            .finally(() => setIsLoadingProducts(false));
    }, []);

    useEffect(() => {
        setOrder({
                items: products.filter((p) => p.amount !== 0).map((p): ProductStoreItemRequest => {
                        return {productSlug: p.slug, amount: p.amount! * p.productCost};
                    }
                )
            }
        );
    }, [products]);

    useEffect(() => {
        setTotalOrderPrice(order.items.reduce((acc, product) => acc + product.amount, 0));
    }, [order]);

    useEffect(() => {
        if (currentStoreStep > storeSteps.length)
            navigate(ApplicationRoutes.payment, {state: fakeNetopia});
    }, [currentStoreStep]);

    const nextStep = () => {
        setCurrentStoreStep(currentStoreStep + 1);
    }

    const handleAmountChange = (slug: string, newAmount: number) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.slug === slug
                    ? {...product, amount: newAmount}
                    : product
            )
        );
    };

    return (
        <div className={"flex flex-col"}>
            <div className={"h-fit flex justify-center items-center"}>
                <div className="flex justify-center items-center p-4">
                    {storeSteps.map((step, index) => (
                        <>
                            <div
                                key={index}
                                className={`scale-110 flex border-4 border-accent-main-500 justify-center items-center w-10 h-10 text-lg rounded-full ${index + 1 <= currentStoreStep ? 'bg-accent-main-500 text-white' : 'text-accent-main-500'}`}>
                                {step}
                            </div>
                            {
                                (index + 1) === storeSteps.length
                                    ? <></>
                                    : <div
                                        className={`w-10 border-b-4 ${index + 1 <= currentStoreStep ? 'border-accent-main-500 text-white' : ''}`}/>
                            }
                        </>
                    ))}
                </div>
            </div>
            <div className={"flex flex-col"}>
                <div className="overflow-x-auto bg-white p-1 rounded-lg">
                    <table className="min-w-full">
                        <thead className="border-b">
                        <tr className={"text-left"}>
                            <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider border-r">
                                Nume
                            </th>
                            <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-[150px]">
                                Pret
                            </th>
                            <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-[150px] border-r">
                                Credite
                            </th>
                            <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-[200px]">
                                Pret final
                            </th>
                            {
                                currentStoreStep === 1 ?
                                    <th className="px-6 py-3 text-sm font-medium text-gray-500 uppercase tracking-wider w-[50px]">
                                        Cantitate
                                    </th>
                                    : <></>
                            }
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {isLoadingProducts
                            ? <Loading color={"fill-accent-main-500"}/>
                            : (currentStoreStep !== 1 ? products.filter((p) => p.amount !== 0) : products).map((product, index) => (
                                <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap border-r">
                                        <div className="text-sm font-medium text-gray-900">
                                            {product.name}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {product.productCost} lei
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 border-r">
                                        {product.productCreditsValue} credite
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {product.productCost * product.amount!} lei
                                    </td>
                                    {
                                        currentStoreStep === 1
                                            ? <td className="px-6 py-4 whitespace-nowrap">
                                                <input type={"number"} className={"w-full border p-2 rounded-lg"}
                                                       value={product.amount!}
                                                       onChange={(e) =>
                                                           handleAmountChange(product.slug, parseInt(e.target.value) || 0)
                                                       }
                                                       min={0} max={100} step={1}/>

                                            </td>
                                            : <></>
                                    }
                                </tr>
                            ))}
                        <tr>
                            <th colSpan={currentStoreStep === 1 ? 5 : 4}>
                                <div className={"p-6 py-2 flex justify-between"}>
                                    <p>Total</p>
                                    <p>{totalOrderPrice} lei</p>
                                </div>
                            </th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"h-fit my-3 flex justify-center items-center gap-2"}>
                {totalOrderPrice === 0
                    ? <></>
                    : <button
                        className={"py-2.5 px-5 bg-accent-main-500 text-white rounded-lg"}
                        onClick={nextStep}>
                        {
                            currentStoreStep === storeSteps.slice(-1)[0]
                                ? <div className={"gap-2 flex justify-center items-center"}>
                                    <p>Cumpara</p>
                                    <CiShop/>
                                </div>
                                : <div className={"gap-2 flex justify-center items-center"}>
                                    <p>Urmatorul pas</p>
                                    <IoChevronForward/>
                                </div>
                        }
                    </button>
                }
            </div>
        </div>
    );
}