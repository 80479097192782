import ChildRouter from "../commons/functionals/router/ChildRouter";

export default class JobsRouter extends ChildRouter {
    static create = "/create";
    static browse = "/mine";

    static viewJob = "/details/:jobSlug";
    static viewJobEntry = "/details/";

    static edit = "/details/:jobSlug/edit";
    static editEntry = "/edit";
}