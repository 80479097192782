import {Route, Routes, useNavigate} from "react-router-dom";
import PrivateAccessRoute from "../../commons/functionals/auth_logic/route_permitters/PrivateAccessRoute";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import FirmDetails from "../firm-details/FirmDetails";
import React, {useEffect} from "react";
import JobsRouter from "../../routers/JobsRouter";
import JobsCreatePage from "./components/JobsCreatePage";
import ListJobsPage from "./components/ListJobsPage";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import JobDetailsPage from "./components/JobDetailsPage";
import JobsEditPage from "./components/JobsEditPage";

export default function JobRootPage() {

    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === ApplicationRoutes.dashboard + DashboardUserRouter.jobs)
            navigate(ApplicationRoutes.dashboard + DashboardUserRouter.jobs + JobsRouter.browse); // Redirect to the create page
    }, [navigate]);

    return (
        <Routes>
            <Route path={JobsRouter.edit}
                   element={
                       <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                           <JobsEditPage/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={JobsRouter.create}
                   element={
                       <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                           <JobsCreatePage/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={JobsRouter.browse}
                   element={
                       <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                           <ListJobsPage/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={JobsRouter.viewJob}
                   element={
                       <PrivateAccessRoute permittedRole={[UserRoles.ROLE_EMPLOYER]}>
                           <JobDetailsPage/>
                       </PrivateAccessRoute>
                   }/>
        </Routes>
    );
}